import React, { cloneElement } from 'react';

import { FormattedMessage } from 'react-intl';

import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './MapHeading.scss';
import BackArrow from 'assets/svg/icons/back-arrow.svg';

interface IProps {
  heading: string;
  map?: React.ReactElement<any>;
  available?: string;
  location?: string;
  noOffset?: boolean;
  backLink?: React.ReactElement<any>;
}

export const MapHeading = ({ heading, map, available, location, noOffset = false, backLink }: IProps) => {

  const hasContent = available || location;

  return (
    <Segment container>
      <div className={s('mapHeading', {  hasContent, noOffset })}>
        <Row>
          <div className={s.mapHeading__headingWrap}>
            <h2 className={s.mapHeading__heading}>{heading}</h2>
          </div>
          <div className={s.mapHeading__content}>
            {map && (
              <div className={s.mapHeading__map}>
                {map}
              </div>
            )}

            { (available || location) && (
            <dl className={s.mapHeading__dl}>
            { available && (
                <div>
                  <dt className={s.mapHeading__dt}><FormattedMessage id="available" /></dt>
                  <dd className={s.mapHeading__dd}>{available}</dd>
                </div>
              )}
              { location && (
                <div>
                  <dt className={s.mapHeading__dt}><FormattedMessage id="location" /></dt>
                  <dd className={s.mapHeading__dd}>{location}</dd>
                </div>
              )}
            </dl>
            )}
          </div>
        </Row>
        {backLink && (
            <div className={s.mapHeading__back}>
              {cloneElement(backLink, { className: s.mapHeading__backLink})} <BackArrow className={s.mapHeading__arrow} />
            </div>)}
      </div>
    </Segment>
  );
};

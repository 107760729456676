import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';

// https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md
import MarkerImage from 'assets/svg/icons/marker.svg';

interface IProps {
  markers: any;
  width: any;
  height: any;
  zoom?: any;
  center?: any;
}

export default class Mapbox extends PureComponent<IProps> {

  static defaultProps = {
    width: 1500,
    height: 1500,
  };

  state = {
    width: this.props.width,
    height: this.props.height,
    isMounted: false,
  };

  ReactMapboxGl = null;
  Map = null;

  componentDidMount() {
    this.ReactMapboxGl = require('react-mapbox-gl');
    const Wrapper = this.ReactMapboxGl.default;

    this.Map = Wrapper({
      accessToken: 'pk.eyJ1IjoiYmphcm5pZiIsImEiOiJjamV6ZmV1cXkwOGNpMzNwY2ZqOTdiZGNzIn0.ZzPWTCJOgVXrQRM27wzmyw',
    });

    this.setState({ isMounted: true }); // eslint-disable-line

    this.forceUpdate(); // ugh. sorry
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.width !== this.state.width
      || nextProps.height !== this.state.height) {
      this.setState({ width: nextProps.width, height: nextProps.height });
    }
  }

  render() {
    if (!this.state.isMounted) { return null; }

    const { Marker } = this.ReactMapboxGl;
    const { zoom, markers, center } = this.props;

    const { Map } = this;

    // Default value is center of Iceland
    const mapCenter = !isEmpty(center) ? center : [-18.905401, 64.770977];

    // Default zoom is 7
    const mapZoom = !isEmpty(zoom) ? zoom : [7];

    return (
      <Map
        zoom={mapZoom}
        containerStyle={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        style="mapbox://styles/bjarnif/cjsui8xo457k01fnvf0kqwmsj"
        center={mapCenter}
      >
        {markers.map((marker: any, i: number) => (
          <Marker
            key={`marker${i + 1}`}
            coordinates={[marker.longitude, marker.latitude]}
            anchor="bottom"
          >
            <MarkerImage />
          </Marker>
        ))}
      </Map>
    );
  }
}
